import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { TopicFromSlug } from "./AllTopics";
import { FormatFromSlug } from "./AllFormats";
import { I18nextContext } from "gatsby-plugin-react-i18next";


export default function ArticleMeta(props) {
	const {
		date,
		format,
		topics,
		relatedTopics,
		titleText,
		publishedText,
		formatText,
		topicText,
	} = props;

	const lng = React.useContext(I18nextContext).language;

	return (
		<StaticQuery
			query={graphql`
				query MetaLinks {
					topics: allMarkdownRemark(
						filter: { frontmatter: { templateKey: { eq: "topics" } } }
					) {
						edges {
							node {
								fields {
									slug
									language
								}
								frontmatter {
									title
								}
							}
						}
					}
					formats: allMarkdownRemark(
						filter: { frontmatter: { templateKey: { eq: "formats" } } }
					) {
						edges {
							node {
								fields {
									slug
									language
								}
								frontmatter {
									title
								}
							}
						}
					}
				}
			`}
			render={(data) => (
				<div className="meta-box article-meta-box article-metadata text-xs font-sans  text-stone-400">
					<h1 className="meta-header">{titleText}</h1>
					<p>
						{publishedText}:<span>{date}</span>
					</p>
					<p>
						{formatText}:
						<span>
							<Link
								to={
									data.formats.edges.find(
										(x) => x.node.fields.slug.match(/[^/]+(?=\/$|$)/)[0] === format && x.node.fields.language === lng
									).node.fields.slug
								}
							>
								<FormatFromSlug slug={format} />
							</Link>
						</span>
					</p>
					<p className="break-all">
						{topicText}:
						<span className="whitespace-nowrap">
							<Link
								to={
									data.topics.edges.find(
										(x) => x.node.fields.slug.match(/[^/]+(?=\/$|$)/)[0] === topics && x.node.fields.language === lng
									).node.fields.slug
								}
							>
								{/* HERE IS THE FANTASTIC FIND FUNCTION TO COMPARE OBJECTS BETWEEN VALUES, BY JOVE */}

								<TopicFromSlug slug={topics} />
							</Link>
						</span>
						{relatedTopics && relatedTopics.length ? (
							<>
								{relatedTopics.map((topic) => (
									<span className="whitespace-nowrap">
										<Link
											to={
												data.topics.edges.find(
													(x) => x.node.fields.slug.match(/[^/]+(?=\/$|$)/)[0] === topic && x.node.fields.language === lng
												).node.fields.slug
											}
										>
											<TopicFromSlug slug={topic} />
										</Link>
									</span>
								))}
							</>
						) : null}
					</p>
				</div>
			)}
		/>
	);
}
