import React from "react";
import {
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";
import { SiTwitter, SiFacebook, SiWhatsapp } from "react-icons/si";
import { HiMail } from "react-icons/hi";

export default function Socials(props) {
	const { url, title, description } = props;
	return (
		<div className="social-meta-box meta-box mt-4 border-t-2 border-gecko-red pt-4">
			<div className="social-shares flex space-x-2 justify-between">
				<EmailShareButton
					url={url}
					className="socialButton"
					subject={"From The Gecko Project: " + title}
					body={description}
				>
					<HiMail className="h-4 w-4 fill-stone-200 hover:fill-gecko-red" />
				</EmailShareButton>
				<FacebookShareButton url={url} className="socialButton">
					<SiFacebook className="h-4 w-4 fill-stone-200 hover:fill-gecko-red" />
				</FacebookShareButton>
				<TwitterShareButton
					url={url}
					className="socialButton"
					title={title}
					via="geckoproj"
				>
					<SiTwitter className="h-4 w-4 fill-stone-200 hover:fill-gecko-red" />
				</TwitterShareButton>
				<WhatsappShareButton url={url} className="socialButton" title={title}>
					<SiWhatsapp className="h-4 w-4 fill-stone-200 hover:fill-gecko-red" />
				</WhatsappShareButton>
			</div>
		</div>
	);
}
