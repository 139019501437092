import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import { TableOfContents } from "../components/Toc";
import PartnersBox from "../components/PartnersBox";
import Socials from "../components/Socials";
import ArticleMeta from "../components/ArticleMeta";
import {
	Link,
	useTranslation,
	I18nextContext,
} from "gatsby-plugin-react-i18next";
import { TopicFromSlug } from "../components/AllTopics";

// eslint-disable-next-line
export const ArticleTemplate = ({
	content,
	contentComponent,
	description,
	// tags,
	title,
	heroImage,
	topics,
	relatedTopics,
	format,
	partners,
	date,
	toc,
	url,
	slug,
	translationStatus,
}) => {
	const ArticleContent = contentComponent;
	const { t } = useTranslation();
	const lng = React.useContext(I18nextContext).language;
	let i18nSlug;
	if (lng === "id") {
		i18nSlug = slug.substring(3);
	} else {
		i18nSlug = slug;
	}

	return (
		<section className="section">
			<Seo
				title={title}
				image={getSrc(heroImage)}
				description={description}
				article={true}
			/>

			<div className="relative">
				<div className="h-[88vh] brightness-75 transition group-hover:saturate-50  bg-no-repeat bg-center bg-fixed bg-cover">
					<GatsbyImage image={getImage(heroImage)} alt="" className="h-full" />
				</div>

				<div className="absolute bottom-[10%]  text-white w-full">
					<div className="gecko-wrapper">
						<div className="flex justify-between">
							<h2 className="text-xs md:text-l lg:text-xl font-sans uppercase border-b-2 border-gecko-red">
								<TopicFromSlug slug={topics} />
							</h2>
						</div>
						<h1 className="lg:text-6xl text-4xl leading-tight md:text-4xl font-sans lg:py-4 transition">
							{title}
						</h1>
						<p className="font-serif lg:text-l text-xs md:text-base lg:font-semibold font-medium">
							{description}
						</p>
					</div>
				</div>
			</div>
			<div className="gecko-wrapper">
				<div className="grid grid-cols-12 mt-12">
					{/* article grid */}
					<div className="hidden md:block col-span-2">
						{/*left sidebar*/}
						<div className="hidden md:block xl:hidden">
							<ArticleMeta
								date={date}
								format={format}
								topics={topics}
								relatedTopics={relatedTopics}
								titleText={t("common:article.metaAbout")}
								publishedText={t("common:article.publishedOn")}
								formatText={t("common:article.format")}
								topicText={t("common:article.topics")}
							/>
							<PartnersBox
								partners={partners}
								partnersText={t("common:article.partners")}
							/>
							<div className="meta-18n meta-box">
								{translationStatus === "enId" ? (
									lng === "id" ? (
										<Link to={i18nSlug} language="en" className="w-full block">
											<h1 className="meta-i18nLink">Read in English</h1>
										</Link>
									) : (
										<Link to={i18nSlug} language="id" className="w-full ">
											<h1 className="meta-i18nLink">
												Baca dalam Bahasa Indonesia
											</h1>
										</Link>
									)
								) : null}
							</div>
						</div>
						<div className="sticky top-24 ">
							{toc && toc.length ? (
								<>
									<TableOfContents
										nestedHeadings={toc}
										tocHeader={t("common:article.toc")}
									/>
									<Socials url={url} title={title} description={description} />
								</>
							) : null}
						</div>
					</div>
					<div className="col-span-full md:col-span-10 grid grid-cols-10 overflow-x-hidden md:overflow-x-visible">
						{/* article */}
						<ArticleContent
							content={content}
							className="prose order-2 xl:order-1 col-span-full xl:col-span-8 mx-0 md:mx-auto"
						/>
						<div className="block md:hidden xl:block order-1 xl:order-2 col-span-full xl:col-span-2 mx-0 md:mx-24 xl:mx-0">
							{/* right sidebar */}
							<div className="article-metadata grid grid-cols-3 gap-0 md:gap-8 xl:gap-0">
								<ArticleMeta
									date={date}
									format={format}
									topics={topics}
									relatedTopics={relatedTopics}
									titleText={t("common:article.metaAbout")}
									publishedText={t("common:article.publishedOn")}
									formatText={t("common:article.format")}
									topicText={t("common:article.topics")}
								/>
								<PartnersBox
									partners={partners}
									partnersText={t("common:article.partners")}
								/>
								<div className="meta-18n meta-box">
									{translationStatus === "enId" ? (
										lng === "id" ? (
											<Link
												to={i18nSlug}
												language="en"
												className="w-full block"
											>
												<h1 className="meta-i18nLink">Read in English</h1>
											</Link>
										) : (
											<Link to={i18nSlug} language="id" className="w-full ">
												<h1 className="meta-i18nLink">
													Baca dalam Bahasa Indonesia
												</h1>
											</Link>
										)
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

ArticleTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	description: PropTypes.string,
	title: PropTypes.string,
	helmet: PropTypes.object,
};

const Article = ({ data }) => {
	const { markdownRemark: content } = data;

	return (
		<Layout>
			<ArticleTemplate
				content={content.htmlAst}
				contentComponent={HTMLContent}
				description={content.frontmatter.description}
				tags={content.frontmatter.tags}
				title={content.frontmatter.title}
				heroImage={content.frontmatter.featuredimage}
				topics={content.frontmatter.main_topic}
				relatedTopics={content.frontmatter.related_topics}
				format={content.frontmatter.format}
				date={content.frontmatter.date}
				toc={content.headings}
				url={"https://thegeckoproject.org" + content.fields.slug}
				translationStatus={content.frontmatter.translationStatus}
				slug={content.fields.slug}
				////////////////////////////////////////////
				// TODO remove hardcoding of URL
				///////////////////////////////////////////

				// sections={content.frontmatter.sections}
				partners={content.frontmatter.partners}
			/>
		</Layout>
	);
};

Article.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object,
	}),
};

export default Article;

export const pageQuery = graphql`
	query ArticleByID($id: String!, $language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(id: { eq: $id }) {
			id
			html
			htmlAst
			fields {
				slug
			}
			headings(depth: h1) {
				id
				title: value
			}
			frontmatter {
				date(formatString: "DD.MM.YYYY")
				title
				description
				main_topic
				related_topics
				format
				translationStatus
				featuredimage {
					childImageSharp {
						gatsbyImageData(
							width: 2000
							quality: 50
							webpOptions: { quality: 70 }
						)
					}
				}
				partners {
					partnerTitle
					partnerUrl
					partnerLogo {
						childImageSharp {
							gatsbyImageData(height: 250)
						}
					}
				}
			}
		}
	}
`;
