import React from "react";
import { AllFormatsQuery } from "./AllFormatsQuery";
import { I18nextContext } from "gatsby-plugin-react-i18next";

const getFormatList = () => {
	const formatList = [];
	const formats = AllFormatsQuery();
	formats.allMarkdownRemark.edges.forEach((formatEdge) => {
		formatList.push({
			path: formatEdge.node.fields.slug,
			lang: formatEdge.node.fields.language,
			title: formatEdge.node.frontmatter.title,
			id: formatEdge.node.fields.id,
		});
	});
	return formatList;
};


export const FormatFromSlug = (props) => {
	const {slug} = props;
	const formatList = getFormatList();
	const lng = React.useContext(I18nextContext).language;

	return (
		<>
		{formatList.map(
				(format) =>
					format.lang === lng &&
					format.path.match(/[^/]+(?=\/$|$)/)[0] === slug && (
						<>
							{format.title}
						</>
					)
			)}
		</>
	)
}
