import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function PartnersBox(props) {
	const { partners, partnersText } = props;

	return (
		<div className="partnershipContainer partners-meta-box meta-box">
			{partners && partners.length ? (
				<div className="grid grid-cols-2">
					<h1 className="meta-header">{partnersText}</h1>
					{partners.map((partner) => (
						<div className="col-span-1 lg:col-span-full">
							<Link
								to={partner.partnerUrl}
								className="block py-2 pr-4 grayscale hover:grayscale-0"
							>
								<GatsbyImage
									image={getImage(partner.partnerLogo)}
									alt={partner.partnerTitle}
								/>
							</Link>
							{/* <p className="normal-case ">
								<Link to={partner.partnerUrl} className="hover:underline">
									Read their story
								</Link>
							</p> */}
						</div>
					))}
				</div>
			) : null}
		</div>
	);
}
