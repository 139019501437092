import React from "react";

/**
 * This renders an item in the table of contents list.
 * scrollIntoView is used to ensure that when a user clicks on an item, it will smoothly scroll.
 */
const Headings = ({ headings, activeId }) => (
	<ul>
		{headings.map((heading) => (
			<li
				key={heading.id}
				className={
					heading.id === activeId ? "active text-gecko-red font-semibold" : ""
				}
			>
				<a
					href={`#${heading.id}`}
					className="py-1 block"
					onClick={(e) => {
						e.preventDefault();
						document.querySelector(`#${heading.id}`).scrollIntoView({
							behavior: "smooth",
						});
					}}
				>
					{heading.title}
				</a>
			</li>
		))}
	</ul>
);

const useIntersectionObserver = (setActiveId) => {
	const headingElementsRef = React.useRef({});

	React.useEffect(() => {
		const callback = (headings) => {
			headingElementsRef.current = headings.reduce((map, headingElement) => {
				map[headingElement.target.id] = headingElement;
				return map;
			}, headingElementsRef.current);

			// Get all headings that are currently visible on the page
			const visibleHeadings = [];
			// console.log(visibleHeadings)
			Object.keys(headingElementsRef.current).forEach((key) => {
				const headingElement = headingElementsRef.current[key];
				if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
			});

			const getIndexFromId = (id) =>
				headingElements.findIndex((heading) => heading.id === id);

			// If there is only one visible heading, this is our "active" heading

			if (visibleHeadings.length === 1) {
				setActiveId(visibleHeadings[0].target.id);

				// If there is more than one visible heading,
				// choose the one that is closest to the top of the page
			} else if (visibleHeadings.length > 1) {
				const sortedVisibleHeadings = visibleHeadings.sort(
					(a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
				);

				setActiveId(sortedVisibleHeadings[0].target.id);
			}
		};

		const observer = new IntersectionObserver(callback, {
			root: document.querySelector("gecko-wrapper"),
			rootMargin: "500px",
		});

		const headingElements = Array.from(
			document.querySelectorAll("h1:not(.toc-header)")
		);

		// const headingElements = Array.from(headings.id);

		headingElements.forEach((element) => observer.observe(element));

		return () => observer.disconnect();
	}, [setActiveId]);
};

/**
 * Renders the table of contents.
 */
export const TableOfContents = (props) => {
	const [activeId, setActiveId] = React.useState();
	const { nestedHeadings, tocHeader } = props;
	useIntersectionObserver(setActiveId);

	return (
		<nav
			aria-label="Table of contents"
			className="text-xs font-sans  text-stone-400"
		>
			<h1 className="meta-header">{tocHeader}</h1>

			<Headings headings={nestedHeadings} activeId={activeId} />
		</nav>
	);
};
